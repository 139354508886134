/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from 'react'
// reactstrap components
import { Badge, Card, CardBody, Container, Row, Col } from 'reactstrap'
// core components
import HomeNavbar from 'components/Navbars/HomeNavbar.js'
import HomeHeader from 'components/Headers/HomeHeader.js'
import AuthFooter from 'components/Footers/AuthFooter.js'

function Home() {
    return (
        <>
            <HomeNavbar />
            <div className="main-content">
                <HomeHeader />
                <section className="py-6 pb-9 bg-default">
                    <Container fluid>
                        <Row className="justify-content-center text-center">
                            <Col md="6">
                                <h2 className="display-3 text-white">
                                    Our clients
                                </h2>
                                <p className="lead text-white">
                                    Whether you're an investor calculating ROI,
                                    a first-time buyer, or looking for your
                                    dream home, PropIntel equips you with the
                                    essential information to make confident,
                                    strategic property decisions.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="section section-lg pt-lg-0 mt--7">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg="12">
                                <Row>
                                    <Col lg="4">
                                        <Card className="card-lift--hover shadow border-0">
                                            <CardBody className="py-5">
                                                <div className="icon icon-shape bg-gradient-info text-white rounded-circle mb-4">
                                                    <i className="ni ni-check-bold" />
                                                </div>
                                                <h4 className="h3 text-info text-uppercase">
                                                    Property Investors
                                                </h4>
                                                <p className="description mt-3">
                                                    detailed data on property
                                                    prices and local area
                                                    information are crucial for
                                                    making informed investment
                                                    decisions and calculating
                                                    potential returns on
                                                    investment (ROI).
                                                </p>
                                                <div>
                                                    <Badge color="info" pill>
                                                        ROI
                                                    </Badge>
                                                    <Badge color="info" pill>
                                                        Rental yield
                                                    </Badge>
                                                    <Badge color="info" pill>
                                                        Local Demography
                                                    </Badge>
                                                    <Badge color="info" pill>
                                                        Historical prices
                                                    </Badge>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg="4">
                                        <Card className="card-lift--hover shadow border-0">
                                            <CardBody className="py-5">
                                                <div className="icon icon-shape bg-gradient-success text-white rounded-circle mb-4">
                                                    <i className="ni ni-istanbul" />
                                                </div>
                                                <h4 className="h3 text-success text-uppercase">
                                                    First-time Buyers
                                                </h4>
                                                <p className="description mt-3">
                                                    benefit from understanding
                                                    market trends and
                                                    neighborhood amenities,
                                                    helping them choose the best
                                                    area for their initial
                                                    investment.
                                                </p>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg="4">
                                        <Card className="card-lift--hover shadow border-0">
                                            <CardBody className="py-5">
                                                <div className="icon icon-shape bg-gradient-warning text-white rounded-circle mb-4">
                                                    <i className="ni ni-planet" />
                                                </div>
                                                <h4 className="h3 text-warning text-uppercase">
                                                    Primary residence
                                                </h4>
                                                <p className="description mt-3">
                                                    For those looking to
                                                    purchase their primary
                                                    residence, PropIntel
                                                    provides comprehensive local
                                                    insights, including schools,
                                                    transportation, and
                                                    community services, ensuring
                                                    they find a home that meets
                                                    their lifestyle needs.
                                                </p>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="py-6">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col className="order-md-2" md="6">
                                <img
                                    alt="..."
                                    className="img-fluid"
                                    src={require('assets/img/theme/landing-1.png')}
                                />
                            </Col>
                            <Col className="order-md-1" md="6">
                                <div className="pr-md-5">
                                    <h1>Awesome features</h1>
                                    <p>
                                        Register or login for free and start
                                        searching the postcode for your next
                                        residence or investment.
                                    </p>
                                    <ul className="list-unstyled mt-5">
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge
                                                        className="badge-circle mr-3"
                                                        color="success"
                                                    >
                                                        <i className="ni ni-settings-gear-65" />
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h4 className="mb-0">
                                                        Historical prices of the
                                                        area
                                                    </h4>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge
                                                        className="badge-circle mr-3"
                                                        color="success"
                                                    >
                                                        <i className="ni ni-html5" />
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h4 className="mb-0">
                                                        Historical population by
                                                        age range.
                                                    </h4>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge
                                                        className="badge-circle mr-3"
                                                        color="success"
                                                    >
                                                        <i className="ni ni-satisfied" />
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h4 className="mb-0">
                                                        Friendly and easy to
                                                        use. Just login and type
                                                        your future postcode.
                                                    </h4>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
            <AuthFooter />
        </>
    )
}

export default Home

/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext } from 'react'
// react library for routing
import { Link } from 'react-router-dom'
// reactstrap components
import {
    UncontrolledCollapse,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col,
} from 'reactstrap'

import AuthContext from 'context/AuthContext'

function AdminNavbar() {
    let { user, loading, logoutUser, loggedOut } = useContext(AuthContext)
    const logout = (e) => {
        e.preventDefault()
        logoutUser()
    }
    return (
        <>
            <Navbar
                className="navbar-horizontal navbar-main navbar-dark bg-info"
                expand="lg"
                id="navbar-main"
            >
                <Container>
                    <NavbarBrand to="/" tag={Link}>
                        <img
                            alt="..."
                            src={require('assets/img/brand/PropIntel.white.v3.png')}
                            style={{ width: '200px', height: '70px' }}
                        />
                    </NavbarBrand>
                    <button
                        aria-controls="navbar-collapse"
                        aria-expanded={false}
                        aria-label="Toggle navigation"
                        className="navbar-toggler"
                        data-target="#navbar-collapse"
                        data-toggle="collapse"
                        id="navbar-collapse"
                        type="button"
                    >
                        <span className="navbar-toggler-icon" />
                    </button>
                    <UncontrolledCollapse
                        className="navbar-custom-collapse"
                        navbar
                        toggler="#navbar-collapse"
                    >
                        <div className="navbar-collapse-header">
                            <Row>
                                <Col className="collapse-brand" xs="6">
                                    <Link to="/admin/dashboard">
                                        <img
                                            alt="..."
                                            src={require('assets/img/brand/blue.png')}
                                        />
                                    </Link>
                                </Col>
                                <Col className="collapse-close" xs="6">
                                    <button
                                        aria-controls="navbar-collapse"
                                        aria-expanded={false}
                                        aria-label="Toggle navigation"
                                        className="navbar-toggler"
                                        data-target="#navbar-collapse"
                                        data-toggle="collapse"
                                        id="navbar-collapse"
                                        type="button"
                                    >
                                        <span />
                                        <span />
                                    </button>
                                </Col>
                            </Row>
                        </div>
                        <Nav className="mr-auto" navbar>
                            {user ? (
                                <>
                                    <NavItem>
                                        <NavLink
                                            to="/admin/dashboard"
                                            tag={Link}
                                        >
                                            <span className="nav-link-inner--text">
                                                Dashboard
                                            </span>
                                        </NavLink>
                                    </NavItem>
                                </>
                            ) : (
                                <>
                                    <NavItem>
                                        <NavLink to="/auth/login" tag={Link}>
                                            <span className="nav-link-inner--text">
                                                Login
                                            </span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink to="/auth/register" tag={Link}>
                                            <span className="nav-link-inner--text">
                                                Register
                                            </span>
                                        </NavLink>
                                    </NavItem>
                                </>
                            )}
                        </Nav>
                        {user ? (
                            <>
                                <Nav
                                    className="align-items-lg-center ml-lg-auto"
                                    navbar
                                >
                                    <NavItem>
                                        <NavLink onClick={logout} tag={Link}>
                                            <span className="nav-link-inner--text">
                                                Logout
                                            </span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </>
                        ) : (
                            <></>
                        )}
                    </UncontrolledCollapse>
                </Container>
            </Navbar>
        </>
    )
}

export default AdminNavbar

import { useParams } from 'react-router-dom'
import { useState } from 'react'
import axios from 'axios'
import React from 'react'
import { Button, Card, CardHeader, Col } from 'reactstrap'

const EmailVerification = (prop) => {
    const { key } = useParams()
    const django_api = process.env.REACT_APP_PROPINTEL_API
    const sendVerification = async (key) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }
        const body = JSON.stringify({
            key,
        })
        const response = await axios
            .post(
                `${django_api}/dj-rest-auth/registration/verify-email/`,
                body,
                config,
            )
            .catch((e) => {
                console.log('Error')
                console.log(body)
                console.log(e.response.data)
            })
        if (response.status === 200) {
            setVerificationStatus(true)
        }
        return response
    }
    const [verificationStatus, setVerificationStatus] = useState(false)
    const onClick = (e) => {
        e.preventDefault()
        sendVerification(key)
    }

    if (!verificationStatus)
        return (
            <>
                <Col lg="6" md="8">
                    <Card className="bg-secondary shadow border-0">
                        <CardHeader className="bg-transparent pb-5">
                            <div className="text-muted text-center mt-2 mb-4">
                                <small>
                                    <a
                                        href="#pablo"
                                        onClick={(e) => onClick(e)}
                                    >
                                        Click here to verify your email.
                                    </a>
                                </small>
                            </div>
                        </CardHeader>
                    </Card>
                </Col>
            </>
        )
    else {
        return (
            <>
                <Col lg="6" md="8">
                    <Card className="bg-secondary shadow border-0">
                        <CardHeader className="bg-transparent pb-5">
                            <div className="text-muted text-center mt-2 mb-4">
                                <small>email verified.</small>
                            </div>
                        </CardHeader>
                    </Card>
                </Col>
            </>
        )
    }
}

export default EmailVerification

import React, { useCallback, useState } from 'react'
import { Line } from 'react-chartjs-2'
// javascipt plugin for creating charts
import Chart from 'chart.js'
// core components
import { chartOptions, parseOptions } from 'variables/charts.js'
import { parseJsonText } from 'typescript'

// Colors
var themedColors = {
    gray: {
        100: '#f6f9fc',
        200: '#e9ecef',
        300: '#dee2e6',
        400: '#ced4da',
        500: '#adb5bd',
        600: '#8898aa',
        700: '#525f7f',
        800: '#32325d',
        900: '#212529',
    },
    theme: {
        default: '#172b4d',
        primary: '#5e72e4',
        secondary: '#f4f5f7',
        info: '#11cdef',
        success: '#2dce89',
        danger: '#f5365c',
        warning: '#fb6340',
    },
    black: '#12263F',
    white: '#FFFFFF',
    transparent: 'transparent',
}

// Format chart datasets
const colors = [
    '#4169E1', //Royal Blue
    '#2E8B57', //Emerald Green
    '#DC143C', //Crimson Red
    '#8A2BE2', //Plum Purple
    '#FF7F50', //Coral
    '#FFD700', //Gold
    '#87CEEB',
]
const AverageYearlyPrices = (props) => {
    // Step 1: Parse dates to extract years
    const years = props.data.deed_date.map((date) =>
        new Date(date).getFullYear(),
    )
    const uniqueYears = [...new Set(years)]
    // Step 2: Group data by year and bedroom
    const groupedData = {}
    props.data.bedrooms.forEach((bedroom, index) => {
        const year = years[index]
        if (bedroom === '') return
        if (!groupedData[year]) {
            groupedData[year] = {}
        }
        if (!groupedData[year][bedroom]) {
            groupedData[year][bedroom] = []
        }
        const price = parseFloat(props.data.price_paid[index])
        const allowed_values = ['F', 'L', 'T', 'S', 'D']
        if (
            isNaN(year) ||
            isNaN(price) ||
            !allowed_values.includes(props.data.property_type[index])
        ) {
            return
        }
        groupedData[year][bedroom].push(props.data.price_paid[index])
    })
    // Step 3: Calculate average price for each combination of year and bedroom
    const averages = {}
    Object.entries(groupedData).forEach(([year, bedroomsData]) => {
        Object.entries(bedroomsData).forEach(([bedroom, prices]) => {
            const average = prices.length
                ? prices.reduce((acc, price) => acc + price, 0) / prices.length
                : null
            if (!averages[bedroom]) {
                averages[bedroom] = Array(uniqueYears.length).fill(null) // Initialize dataset with null values
            }
            averages[bedroom][uniqueYears.indexOf(parseInt(year))] = average
        })
    })
    // Step 4: Calculate total average for each year
    const totalAverages = uniqueYears.map((year) => {
        const prices = props.data.price_paid.filter(
            (_, index) => years[index] === parseInt(year),
        )
        return prices.length
            ? prices.reduce((acc, price) => acc + price, 0) / prices.length
            : null
    })
    // Step 5: Generate datasets for each bedroom, total, and total average
    var datasets = Object.entries(averages).map(
        ([bedroom, averagesPerYear], index) => ({
            label: bedroom === 'Total' ? 'Total' : `${bedroom} Bed`,
            data: averagesPerYear,
            fill: false,
            borderColor: colors[index % colors.length],
        }),
    )
    // Add dataset for total average
    datasets.push({
        label: 'Total Average',
        data: totalAverages,
        fill: false,
        borderColor: colors[datasets.length % colors.length],
    })
    ////////////////

    if (window.Chart) {
        parseOptions(Chart, chartOptions())
    }
    const [legend, setLegend] = useState()
    const [ref, setRef] = useState()
    const onRefChange = useCallback(
        (node) => {
            //ref value changed to node
            setRef(node) //e.g change ref state to trigger re-render
            if (node != null) {
                let generatedyLegend = node.chartInstance.generateLegend()
                setLegend(
                    <div className="chart-legend">{generatedyLegend}</div>,
                )
            }
        },
        [props],
    )
    let chartExample1 = {
        options: {
            spanGaps: true,
            scales: {
                yAxes: [
                    {
                        gridLines: {
                            color: themedColors.gray[900],
                            zeroLineColor: themedColors.gray[900],
                        },
                        ticks: {
                            callback: function (value) {
                                if (!(value % 10)) {
                                    return '£' + value / 1000 + 'k'
                                }
                            },
                        },
                    },
                ],
            },
            legend: {
                display: false,
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                    padding: 16,
                },
            },
            legendCallback: (chart) => {
                const legendItems = chart.data.datasets.map(
                    (dataset, index) => {
                        return (
                            <span
                                key={index}
                                className="chart-legend-item"
                                style={{ textAlign: 'center' }}
                            >
                                <i
                                    className="chart-legend-indicator"
                                    style={{
                                        width: '1rem',
                                        height: '1rem',
                                        backgroundColor: dataset.borderColor,
                                    }}
                                ></i>
                                <i className="chart-legend-item">
                                    {dataset.label}
                                </i>
                            </span>
                        )
                    },
                )
                return legendItems
            },
            tooltips: {
                callbacks: {
                    label: function (item, data) {
                        var label = data.datasets[item.datasetIndex].label || ''
                        var yLabel = item.yLabel / 1000
                        var content = ''

                        if (data.datasets.length > 1) {
                            content += label
                        }

                        content += '£' + yLabel + 'k'
                        return content
                    },
                    labelColor: (context) => ({
                        borderColor:
                            colors[context.datasetIndex % colors.length],
                        backgroundColor:
                            colors[context.datasetIndex % colors.length],
                    }),
                },
            },
        },
        data: {
            labels: uniqueYears,
            datasets,
        },
    }
    return (
        <>
            <div className="chart">
                <Line
                    data={chartExample1.data}
                    options={chartExample1.options}
                    ref={onRefChange}
                />
            </div>
            {legend}
        </>
    )
}

export default AverageYearlyPrices

import React, { useCallback, useState } from 'react'
import { Doughnut } from 'react-chartjs-2'
// javascipt plugin for creating charts
import Chart from 'chart.js'
// core components
import { chartOptions, parseOptions } from 'variables/charts.js'
import { parseJsonText } from 'typescript'

var backgroundColors = [
    'rgba(255, 99, 132, 0.5)',
    'rgba(54, 162, 235, 0.5)',
    'rgba(255, 206, 86, 0.5)',
    'rgba(75, 192, 192, 0.5)',
    'rgba(153, 102, 255, 0.5)',
    'rgba(255, 159, 64, 0.5)',
]

const TenureOfHousehold = (props) => {
    if (!props.data.tenureOfHouseHold) {
        console.error('Invalid data format')
        return []
    }
    var chartLabels = []
    var chartValues = []
    var total = parseInt(props.data.tenureOfHouseHold.value[0])
    props.data.tenureOfHouseHold.value.forEach((element, index) => {
        var elementValue = parseInt((parseInt(element) / total) * 100)
        if (elementValue != 0 && index != 0) {
            chartValues.push(elementValue)
            chartLabels.push(
                props.data.tenureOfHouseHold['Tenure of household'][index],
            )
        }
    })

    // Example data
    if (window.Chart) {
        parseOptions(Chart, chartOptions())
    }
    const chartData = {
        labels: chartLabels,
        datasets: [
            {
                label: 'Tenure of Household in percentage',
                data: chartValues,
                backgroundColor: backgroundColors,
            },
        ],
    }

    return (
        <>
            <div className="chart">
                <Doughnut data={chartData} />
            </div>
        </>
    )
}

export default TenureOfHousehold

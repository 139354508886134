/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import { useState, useContext } from 'react'
import AuthContext from 'context/AuthContext'
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
} from 'reactstrap'

import { useGoogleLogin } from '@react-oauth/google'
import SignUpEmail from './RegisterAndValidation'
import axios from 'axios'

const Register = () => {
    const django_api = process.env.REACT_APP_PROPINTEL_API
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password2: '',
    })
    const [privacy, setPrivacy] = useState(false)
    const [passwordConfirmed, setPasswordConfirmed] = useState(false)
    const [registrationStatus, setRegistrationStatus] = useState(false)
    let { loginWithGoogle } = useContext(AuthContext)
    const handlePrivacy = (e) => {
        setPrivacy(e.target.checked)
    }
    const handleOnChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target
        if (name === 'password') {
            setFormData({
                ...formData,
                password: value,
            })
        } else if (name === 'confirm_password') {
            if (value === formData.password) {
                setFormData({
                    ...formData,
                    password2: value,
                })
                setPasswordConfirmed(true)
            } else {
                setPasswordConfirmed(false)
            }
        } else {
            setFormData({
                ...formData,
                [name]: value,
            })
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        if (passwordConfirmed && privacy) {
            signupQuery(formData)
        } else {
            console.log("Password doesn't match and Privacy must be accepted")
        }
    }

    const [singupFailure, setSignupFailure] = useState('')
    const signupQuery = async ({
        email,
        first_name,
        last_name,
        password,
        password2,
    }) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }
        const body = JSON.stringify({
            email,
            first_name,
            last_name,
            password,
            password2,
        })
        try {
            let response = await axios.post(
                `${django_api}/dj-rest-auth/registration/`,
                body,
                config,
            )
            if (response.status === 200 || response.status === 201) {
                setRegistrationStatus(true)
                setErrorGoogleSignIn(false)
            }
        } catch (err) {
            setSignupFailure(JSON.stringify(err.response.data))
            setErrorGoogleSignIn(false)
        }
    }
    const [errorGoogleSignIn, setErrorGoogleSignIn] = useState(false)
    const googleLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            const success = await loginWithGoogle(tokenResponse.access_token)
            if (success) {
                setErrorGoogleSignIn(false)
            } else {
                setErrorGoogleSignIn(true)
            }
        },
        onError: () => {
            setErrorGoogleSignIn(true)
        },
    })

    if (!registrationStatus) {
        return (
            <>
                <Col lg="6" md="8">
                    <Card className="bg-secondary shadow border-0">
                        <CardHeader className="bg-transparent pb-5">
                            <div className="text-muted text-center mt-2 mb-4">
                                <small>Sign up with</small>
                            </div>
                            <div className="text-center">
                                <Button
                                    className="btn-neutral btn-icon"
                                    color="default"
                                    href="#pablo"
                                    onClick={(e) => googleLogin()}
                                >
                                    <span className="btn-inner--icon">
                                        <img
                                            alt="..."
                                            src={
                                                require('../../assets/img/icons/common/google.svg')
                                                    .default
                                            }
                                        />
                                    </span>
                                    <span className="btn-inner--text">
                                        Google
                                    </span>
                                </Button>
                            </div>
                            {errorGoogleSignIn && (
                                <div>
                                    <span className="alert-warning h6">
                                        Error signing in with google. Do you
                                        have an account already registered
                                        without google.
                                    </span>
                                </div>
                            )}
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-5">
                            <div className="text-center text-muted mb-4">
                                <small>Or sign up with credentials</small>
                            </div>
                            <Form role="form" onSubmit={(e) => handleSubmit(e)}>
                                <FormGroup>
                                    <InputGroup className="input-group-alternative mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-hat-3" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="First Name"
                                            type="text"
                                            name="first_name"
                                            onChange={(e) => handleOnChange(e)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <InputGroup className="input-group-alternative mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-hat-3" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Last Name"
                                            type="text"
                                            name="last_name"
                                            onChange={(e) => handleOnChange(e)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <InputGroup className="input-group-alternative mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-email-83" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Email"
                                            type="email"
                                            autoComplete="new-email"
                                            name="email"
                                            onChange={(e) => handleOnChange(e)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-lock-circle-open" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Password"
                                            type="password"
                                            autoComplete="new-password"
                                            name="password"
                                            onChange={(e) => handleOnChange(e)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-lock-circle-open" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Confirm Password"
                                            type="password"
                                            autoComplete="new-password"
                                            name="confirm_password"
                                            onChange={(e) => handleOnChange(e)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <div className="text-muted font-italic">
                                    <small>
                                        password strength:{' '}
                                        <span className="text-success font-weight-700">
                                            strong
                                        </span>
                                    </small>
                                </div>
                                <div className="text-muted font-italic">
                                    <small>
                                        password verification:{' '}
                                        {passwordConfirmed ? (
                                            <span className="text-success font-weight-700">
                                                match
                                            </span>
                                        ) : (
                                            <span className="text-danger font-weight-700">
                                                passwords don't match
                                            </span>
                                        )}
                                    </small>
                                </div>
                                <Row className="my-4">
                                    <Col xs="12">
                                        <div className="custom-control custom-control-alternative custom-checkbox">
                                            <input
                                                className="custom-control-input"
                                                id="customCheckRegister"
                                                type="checkbox"
                                                checked={privacy}
                                                onChange={(e) =>
                                                    handlePrivacy(e)
                                                }
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="customCheckRegister"
                                            >
                                                <span className="text-muted">
                                                    I agree with the{' '}
                                                    <a
                                                        href="#pablo"
                                                        onClick={(e) =>
                                                            e.preventDefault()
                                                        }
                                                    >
                                                        Privacy Policy
                                                    </a>
                                                </span>
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="text-center">
                                    <Button
                                        className="mt-4"
                                        color="primary"
                                        type="button"
                                        onClick={(e) => handleSubmit(e)}
                                    >
                                        Create account
                                    </Button>
                                </div>
                                {singupFailure !== '' ? (
                                    <div className="text-center">
                                        <span className="alert-warning h6">
                                            {singupFailure}
                                        </span>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </>
        )
    } else {
        return (
            <>
                <SignUpEmail email={formData.email} />
            </>
        )
    }
}

export default Register

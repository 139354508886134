import React, { useState, useEffect } from 'react'
import { InputNumberFormat } from '@react-input/number-format'
import CurrencyInput from 'react-currency-input-field'

// reactstrap components
import {
    Card,
    CardHeader,
    CardText,
    CardBody,
    Label,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
} from 'reactstrap'
// core components
import Header from 'components/Headers/Header.js'

function ReturnOnInvestment() {
    const [purchasePrice, setPurchasePrice] = useState('')
    const [ltv, setLtv] = useState(0)
    const [deposit, setDeposit] = useState('')
    const [refurbishmentCost, setRefurbishmentCost] = useState(0)
    const [refinanceLtv, setRefinanceLtv] = useState('')
    const [newPropertyValue, setNewPropertyValue] = useState('')
    const [monthlyRent, setMonthlyRent] = useState('')
    const [stampDutyTax, setStampDutyTax] = useState(0)
    const [isFirstTimeBuyer, setIsFirstTimeBuyer] = useState(false)
    const [serviceCharge, setServiceCharge] = useState(0)
    const [otherFees, setOtherFees] = useState(0)
    const [mortgageInterest, setMortgageInterest] = useState(0)
    const [newMortgageInterest, setNewMortgageInterest] = useState(0)

    // Calculations for Stamp Duty Tax
    useEffect(() => {
        const calculateStampDuty = (price, isFirstTime) => {
            let tax = 0
            if (price > 0) {
                if (isFirstTime) {
                    if (price <= 425000) {
                        tax = 0
                    } else if (price <= 625000) {
                        tax = (price - 425000) * 0.05
                    } else {
                        tax = 10000 + (price - 625000) * 0.1
                    }
                } else {
                    if (price <= 250000) {
                        tax = 0 + price * 0.03
                    } else if (price <= 925000) {
                        tax = (price - 250000) * 0.05 + price * 0.03
                    } else if (price <= 1500000) {
                        tax = 33750 + (price - 925000) * 0.1 + price * 0.03
                    } else {
                        tax = 88750 + (price - 1500000) * 0.12 + price * 0.03
                    }
                }
            }
            return tax
        }
        setStampDutyTax(calculateStampDuty(purchasePrice, isFirstTimeBuyer))
    }, [purchasePrice, isFirstTimeBuyer])

    // Calculate Deposit
    useEffect(() => {
        if (purchasePrice && ltv) {
            setDeposit(purchasePrice - (purchasePrice * ltv) / 100)
        }
    }, [purchasePrice, ltv])

    // Calculate Return on Cash Invested and Gross Yield
    const calculateReturnOnCashInvested = () => {
        var cashAfterRefinance = 0
        if (
            parseFloat(refinanceLtv) != 0 &&
            parseFloat(newMortgageInterest) != 0 &&
            parseFloat(newPropertyValue) != 0
        ) {
            var loanProvided =
                (parseFloat(newPropertyValue) * parseFloat(refinanceLtv)) / 100
            var loanToReimburse =
                (parseFloat(purchasePrice) * parseFloat(ltv)) / 100
            cashAfterRefinance = loanProvided - loanToReimburse
        }
        var totalInvestment =
            parseFloat(deposit) +
            parseFloat(refurbishmentCost) +
            stampDutyTax +
            parseFloat(serviceCharge)
        const yearlyMortgagePayment =
            (parseFloat(purchasePrice) * mortgageInterest) / 100
        totalInvestment += yearlyMortgagePayment
        totalInvestment -= cashAfterRefinance
        const annualRent = parseFloat(monthlyRent) * 12
        if (totalInvestment > 0) {
            const returnOnCash = (annualRent / totalInvestment) * 100

            return returnOnCash.toFixed(2)
        } else {
            return annualRent
        }
    }

    const calculateGrossYield = () => {
        const annualRent = parseFloat(monthlyRent) * 12
        const grossYield = (annualRent / purchasePrice) * 100
        return grossYield.toFixed(2)
    }

    return (
        <>
            <Header />
            <Container className="mt--6" fluid>
                <Card className="mb-4">
                    <CardHeader>
                        <h3 className="mb-0">
                            Return on investment calculation
                        </h3>
                    </CardHeader>
                    <CardBody>
                        <CardText>
                            Input information about your investment to get an
                            estimation of yield and the return on cash
                            investment.
                        </CardText>
                    </CardBody>
                </Card>
                <Card className="mb-4">
                    <CardHeader>
                        <h3 className="mb-0">Returns and calculations</h3>
                    </CardHeader>
                    <CardBody>
                        <CardText>
                            These are estimates which can vary depending on
                            legal fees and other expenses not mentioned.
                        </CardText>
                        <Row>
                            <Col sm="6">
                                <Form>
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="returnOnCashInvested"
                                        >
                                            Return on cash invested
                                        </label>
                                        <CurrencyInput
                                            id="purchase-price"
                                            suffix="%"
                                            locales="en"
                                            value={calculateReturnOnCashInvested()}
                                            className="form-control"
                                            readOnly
                                            style={{
                                                backgroundColor: '#e9ecef',
                                            }}
                                        />
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col sm="6">
                                <Form>
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="returnOnCashInvested"
                                        >
                                            Deposit
                                        </label>
                                        <CurrencyInput
                                            id="purchase-price"
                                            prefix="£"
                                            locales="en"
                                            value={deposit}
                                            className="form-control"
                                            readOnly
                                            style={{
                                                backgroundColor: '#e9ecef',
                                            }}
                                        />
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6">
                                <Form>
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="returnOnCashInvested"
                                        >
                                            Groos Yield
                                        </label>
                                        <CurrencyInput
                                            id="gross-yield"
                                            suffix="%"
                                            locales="en"
                                            value={calculateGrossYield()}
                                            className="form-control"
                                            readOnly
                                            style={{
                                                backgroundColor: '#e9ecef',
                                            }}
                                        />
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Row className="mb-4">
                    <Col lg="6">
                        <div className="card-wrapper">
                            <Card>
                                <CardHeader>
                                    <h3 className="mb-0">Purchase</h3>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="purchasePrice"
                                            >
                                                Purchase price
                                            </label>
                                            <CurrencyInput
                                                id="purchase-price"
                                                prefix="£"
                                                locales="en"
                                                value={purchasePrice}
                                                placeholder="£"
                                                onValueChange={(value) => {
                                                    setPurchasePrice(value)
                                                }}
                                                className="form-control"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <div className="custom-control custom-checkbox mb-3">
                                                <input
                                                    className="custom-control-input"
                                                    id="first-time-buyer"
                                                    type="checkbox"
                                                    checked={isFirstTimeBuyer}
                                                    onChange={(e) =>
                                                        setIsFirstTimeBuyer(
                                                            e.target.checked,
                                                        )
                                                    }
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor="first-time-buyer"
                                                >
                                                    First time buyer?
                                                </label>
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="stampDuty"
                                            >
                                                Stamp duty tax
                                            </label>
                                            <CurrencyInput
                                                id="stamp-duty"
                                                prefix="£"
                                                value={stampDutyTax}
                                                readOnly
                                                style={{
                                                    backgroundColor: '#e9ecef',
                                                }}
                                                className="form-control"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="stampDuty"
                                            >
                                                Other Purchase Fees
                                            </label>
                                            <CurrencyInput
                                                id="purchase-fees"
                                                prefix="£"
                                                value={otherFees}
                                                placeholder="£"
                                                onValueChange={(value) => {
                                                    setOtherFees(value)
                                                }}
                                                className="form-control"
                                            />
                                        </FormGroup>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="card-wrapper">
                            <Card>
                                <CardHeader>
                                    <h3 className="mb-0">Mortgage and works</h3>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <FormGroup className="row">
                                            <Label
                                                className="form-control-label"
                                                htmlFor="loan-to-value"
                                                md="3"
                                            >
                                                Loan to value
                                            </Label>
                                            <Col md="9">
                                                <CurrencyInput
                                                    id="loan-to-value"
                                                    suffix="%"
                                                    value={ltv}
                                                    onValueChange={(value) =>
                                                        setLtv(value)
                                                    }
                                                    className="form-control"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="row">
                                            <Label
                                                className="form-control-label"
                                                htmlFor="deposit"
                                                md="3"
                                            >
                                                Deposit
                                            </Label>
                                            <Col md="9">
                                                <CurrencyInput
                                                    prefix="£"
                                                    id="deposit"
                                                    value={deposit}
                                                    readOnly
                                                    style={{
                                                        backgroundColor:
                                                            '#e9ecef',
                                                    }}
                                                    className="form-control"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="row">
                                            <Label
                                                className="form-control-label"
                                                htmlFor="mortgage-interest"
                                                md="3"
                                            >
                                                Mortgage interest
                                            </Label>
                                            <Col md="9">
                                                <CurrencyInput
                                                    id="mortgage-interest"
                                                    suffix="%"
                                                    value={mortgageInterest}
                                                    onValueChange={(value) =>
                                                        setMortgageInterest(
                                                            value,
                                                        )
                                                    }
                                                    className="form-control"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="row">
                                            <Label
                                                className="form-control-label"
                                                htmlFor="refurbishment"
                                                md="3"
                                            >
                                                Refurbishment
                                            </Label>
                                            <Col md="9">
                                                <CurrencyInput
                                                    prefix="£"
                                                    id="refurbishment-cost"
                                                    value={refurbishmentCost}
                                                    onValueChange={(value) =>
                                                        setRefurbishmentCost(
                                                            value,
                                                        )
                                                    }
                                                    className="form-control"
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6">
                        <div className="card-wrapper">
                            <Card>
                                <CardHeader>
                                    <h3 className="mb-0">Refinance</h3>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <FormGroup className="row">
                                            <Label
                                                className="form-control-label"
                                                htmlFor="refinance-loan-to-value"
                                                md="4"
                                            >
                                                Refinance LtV
                                            </Label>
                                            <Col md="8">
                                                <CurrencyInput
                                                    id="refinance-loan-to-value"
                                                    suffix="%"
                                                    placeholder="%"
                                                    value={refinanceLtv}
                                                    onValueChange={(value) =>
                                                        setRefinanceLtv(value)
                                                    }
                                                    className="form-control"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="row">
                                            <Label
                                                className="form-control-label"
                                                htmlFor="new-property-value"
                                                md="4"
                                            >
                                                New Property Value
                                            </Label>

                                            <Col md="8">
                                                <CurrencyInput
                                                    placeholder="£"
                                                    prefix="£"
                                                    id="new-property-value"
                                                    value={newPropertyValue}
                                                    onValueChange={(value) =>
                                                        setNewPropertyValue(
                                                            value,
                                                        )
                                                    }
                                                    className="form-control"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="row">
                                            <Label
                                                className="form-control-label"
                                                htmlFor="new-mortgage-interest"
                                                md="4"
                                            >
                                                New Mortgage Interest
                                            </Label>

                                            <Col md="8">
                                                <CurrencyInput
                                                    placeholder="%"
                                                    suffix="%"
                                                    id="new-mortgage-interest"
                                                    value={newMortgageInterest}
                                                    onValueChange={(value) =>
                                                        setNewMortgageInterest(
                                                            value,
                                                        )
                                                    }
                                                    className="form-control"
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="card-wrapper">
                            <Card>
                                <CardHeader>
                                    <h3 className="mb-0">Rental</h3>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <FormGroup className="row">
                                            <Label
                                                className="form-control-label"
                                                htmlFor="loan-to-value"
                                                md="4"
                                            >
                                                Monthly Rent
                                            </Label>
                                            <Col md="8">
                                                <CurrencyInput
                                                    placeholder="£"
                                                    prefix="£"
                                                    id="loan-to-value"
                                                    value={monthlyRent}
                                                    onValueChange={(value) =>
                                                        setMonthlyRent(value)
                                                    }
                                                    className="form-control"
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="row">
                                            <Label
                                                className="form-control-label"
                                                htmlFor="service-charge"
                                                md="4"
                                            >
                                                Yearly Service Charge
                                            </Label>
                                            <Col md="8">
                                                <CurrencyInput
                                                    placeholder="£"
                                                    prefix="£"
                                                    id="service-charge"
                                                    value={serviceCharge}
                                                    onValueChange={(value) =>
                                                        setServiceCharge(value)
                                                    }
                                                    className="form-control"
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ReturnOnInvestment

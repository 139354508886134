import React, { useState } from 'react'
import {
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    CardFooter,
    CardHeader,
    Button,
    Card,
    Row,
} from 'reactstrap'

const TableOfPrices = ({ data }) => {
    const {
        price_paid,
        fullAddress,
        bedrooms,
        deed_date,
        previous_price_paid,
    } = data

    // Create data structure that holds all required fields
    const combinedData = price_paid
        .map((price, index) => ({
            price_paid: price,
            address: fullAddress[index],
            bedrooms: bedrooms[index],
            date: deed_date[index],
            previous_price_paid: previous_price_paid[index],
        }))
        .reverse() // Reverse to get data in descending order

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1)
    const itemsPerPage = 10

    // Calculate the number of pages
    const totalPages = Math.ceil(combinedData.length / itemsPerPage)

    // Determine the data for the current page
    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
    }

    // Calculate the percentage change
    const calculatePercentageChange = (current, previous) => {
        if (!previous) return 'N/A'
        const change = ((current - previous) / previous) * 100
        return change.toFixed(2)
    }

    // Create rows for the table
    const rows = combinedData.slice(startIndex, endIndex).map((item, index) => {
        const percentageChange = calculatePercentageChange(
            item.price_paid,
            item.previous_price_paid,
        )
        const isPositiveChange = parseFloat(percentageChange) > 0
        return (
            <tr key={index}>
                <th scope="row">{item.address}</th>
                <td>{item.price_paid.toLocaleString()}</td>
                <td>
                    {item.previous_price_paid ? (
                        <>
                            <i
                                className={`fas fa-arrow-${
                                    isPositiveChange
                                        ? 'up text-success'
                                        : 'down text-warning'
                                } mr-3`}
                            />
                            {percentageChange}%
                        </>
                    ) : (
                        'N/A'
                    )}
                </td>
                <td>{item.bedrooms || 'N/A'}</td>
                <td>{new Date(item.date).toLocaleDateString()}</td>
            </tr>
        )
    })

    return (
        <>
            <Card className="shadow">
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <div className="col">
                            <h3 className="mb-0">Sold prices in the area</h3>
                        </div>
                    </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">Address</th>
                            <th scope="col">Price paid</th>
                            <th scope="col">Percentage Change</th>
                            <th scope="col">Bedrooms</th>
                            <th scope="col">Date</th>
                        </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </Table>
                <CardFooter>
                    <Pagination className="pagination-sm">
                        <PaginationItem disabled={currentPage === 1}>
                            <PaginationLink
                                previous
                                onClick={() =>
                                    handlePageChange(currentPage - 1)
                                }
                            />
                        </PaginationItem>
                        <PaginationItem disabled={currentPage === totalPages}>
                            <PaginationLink
                                next
                                onClick={() =>
                                    handlePageChange(currentPage + 1)
                                }
                            />
                        </PaginationItem>
                        <div className="col text-right">
                            <PaginationItem>
                                {currentPage} of {totalPages}
                            </PaginationItem>
                        </div>
                    </Pagination>
                </CardFooter>
            </Card>
        </>
    )
}

export default TableOfPrices

/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import AuthContext from 'context/AuthContext'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
} from 'reactstrap'
import { useGoogleLogin } from '@react-oauth/google'

const Login = () => {
    const [emailAndPassword, setEmailAndPassword] = useState({
        email: '',
        password: '',
    })
    const [errorGoogleSignIn, setErrorGoogleSignIn] = useState(false)
    const [errorSignIn, setErrorSignIn] = useState(false)
    let { loginUser, loginWithGoogle } = useContext(AuthContext)
    const handleOnChange = (e) => {
        e.preventDefault()
        const { type, value } = e.target
        setEmailAndPassword({
            ...emailAndPassword,
            [type]: value,
        })
    }
    const { email, password } = emailAndPassword
    const handleOnSubmit = async (e) => {
        e.preventDefault()
        const success = await loginUser(email, password)
        if (success) {
            navigate('/admin')
            setErrorSignIn(false)
            setErrorGoogleSignIn(false)
        } else {
            setErrorGoogleSignIn(false)
            setErrorSignIn(true)
        }
    }
    const googleLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            const success = await loginWithGoogle(tokenResponse.access_token)
            if (success) {
                setErrorGoogleSignIn(false)
                setErrorSignIn(false)
            } else {
                setErrorSignIn(false)
                setErrorGoogleSignIn(true)
            }
        },
        onError: () => {
            setErrorSignIn(false)
            setErrorGoogleSignIn(true)
        },
    })
    const navigate = useNavigate()
    return (
        <>
            <Col lg="5" md="7">
                <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-transparent pb-5">
                        <div className="text-muted text-center mt-2 mb-3">
                            <small>Sign in with</small>
                        </div>
                        <div className="btn-wrapper text-center">
                            <Button
                                className="btn-neutral btn-icon"
                                color="default"
                                href="#pablo"
                                onClick={(e) => googleLogin()}
                            >
                                <span className="btn-inner--icon">
                                    <img
                                        alt="..."
                                        src={
                                            require('../../assets/img/icons/common/google.svg')
                                                .default
                                        }
                                    />
                                </span>
                                <span className="btn-inner--text">Google</span>
                            </Button>
                        </div>
                        {errorGoogleSignIn && (
                            <div>
                                <span className="alert-warning h6">
                                    Error signing in with google. Do you have an
                                    account already registered without google.
                                </span>
                            </div>
                        )}
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                        <div className="text-center text-muted mb-4">
                            <small>Or sign in with credentials</small>
                        </div>
                        <Form role="form" onSubmit={(e) => handleOnSubmit(e)}>
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-email-83" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Email"
                                        type="email"
                                        autoComplete="new-email"
                                        onChange={(e) => handleOnChange(e)}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-lock-circle-open" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Password"
                                        type="password"
                                        autoComplete="new-password"
                                        onChange={(e) => handleOnChange(e)}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <div className="custom-control custom-control-alternative custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id=" customCheckLogin"
                                    type="checkbox"
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor=" customCheckLogin"
                                >
                                    <span className="text-muted">
                                        Remember me
                                    </span>
                                </label>
                            </div>
                            <div className="text-center">
                                <Button
                                    className="my-4"
                                    color="primary"
                                    type="button"
                                    onClick={(e) => handleOnSubmit(e)}
                                >
                                    Sign in
                                </Button>
                                {errorSignIn ? (
                                    <div>
                                        <span className="alert-warning h6">
                                            Error signing in.
                                        </span>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </Form>
                    </CardBody>
                </Card>
                <Row className="mt-3">
                    <Col xs="6">
                        <a
                            className="text-light"
                            href="#pablo"
                            onClick={(e) => {
                                e.preventDefault()
                                navigate('/auth/reset_password')
                            }}
                        >
                            <small>Forgot password?</small>
                        </a>
                    </Col>
                    <Col className="text-right" xs="6">
                        <a
                            className="text-light"
                            href="#pablo"
                            onClick={(e) => {
                                e.preventDefault()
                                navigate('auth/register')
                            }}
                        >
                            <small>Create new account</small>
                        </a>
                    </Col>
                </Row>
            </Col>
        </>
    )
}

export default Login

import React, { useState } from 'react'
import {
    GoogleMap,
    Marker,
    InfoWindow,
    useLoadScript,
    Polygon,
} from '@react-google-maps/api'
import { CardHeader, Card, Row } from 'reactstrap'

const MapWithPins = ({ data, postcode, geocodedData }) => {
    const [selectedPin, setSelectedPin] = useState(null)

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: ['places'], // Add libraries you need here
    })
    const handleMarkerClick = (pin) => {
        setSelectedPin(pin)
    }
    const { currentPostcode, lat, lng, viewport } = geocodedData
    /* const { currentPostcode, lat, lng, bounds } = geocodedData
     */
    const polygonCoordinates = [
        { lat: viewport.northeast.lat, lng: viewport.northeast.lng },
        { lat: viewport.northeast.lat, lng: viewport.southwest.lng },
        { lat: viewport.southwest.lat, lng: viewport.southwest.lng },
        { lat: viewport.southwest.lat, lng: viewport.northeast.lng },
    ]
    if (!isLoaded) {
        return <div>Loading...</div>
    }

    return (
        <Card className="shadow">
            <CardHeader className="border-0">
                <Row className="align-items-center">
                    <div className="col">
                        <h3 className="mb-0">
                            Planning applications in the borough
                        </h3>
                    </div>
                </Row>
            </CardHeader>
            <GoogleMap
                mapContainerStyle={{ height: '400px', width: '100%' }}
                zoom={12}
                center={{ lat: lat, lng: lng }} // Default center, adjust as needed
            >
                {data.hits.hits.map((hit) => {
                    const centroid = hit._source?.centroid
                    const lat = centroid?.lat
                    const lon = centroid?.lon

                    if (lat !== undefined && lon !== undefined) {
                        return (
                            <Marker
                                key={hit._id}
                                position={{ lat, lng: lon }}
                                onClick={() => handleMarkerClick(hit._source)}
                            />
                        )
                    }
                    return null
                })}

                {selectedPin && (
                    <InfoWindow
                        position={{
                            lat: selectedPin.centroid?.lat || 0,
                            lng: selectedPin.centroid?.lon || 0,
                        }}
                        onCloseClick={() => setSelectedPin(null)}
                    >
                        <div>
                            <h4>{selectedPin.site_name}</h4>
                            <p>{selectedPin.description}</p>
                            <p>
                                <strong>Last Updated:</strong>{' '}
                                {selectedPin.last_updated}
                            </p>
                            <p>
                                <strong>Decision:</strong>{' '}
                                {selectedPin.decision}
                            </p>
                        </div>
                    </InfoWindow>
                )}
                <Polygon
                    paths={polygonCoordinates}
                    options={{
                        strokeColor: '#FF0000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#FF0000',
                        fillOpacity: 0.35,
                    }}
                />
            </GoogleMap>
        </Card>
    )
}

export default MapWithPins

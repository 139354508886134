import { useEffect, useState, useContext } from 'react'
import AuthContext from 'context/AuthContext'
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Spinner,
    Col,
} from 'reactstrap'

// core components
import {
    chartOptions,
    parseOptions,
    chartExample1,
    chartExample2,
} from 'variables/charts.js'

import Header from 'components/Headers/Header.js'
import AverageYearlyPrices from 'components/Dashboard/AverageYearlyPrices'
import LastestSoldPrices from 'components/Dashboard/LatestSoldProperties'
import TenureOfHousehold from 'components/Dashboard/TenureOfHousehold'

import axios from 'axios'
import PopulationByAge from 'components/Dashboard/PopulationByAge'
import EconomicActivity from 'components/Dashboard/EconomicActivity'
import LevelOfEducation from 'components/Dashboard/LevelOfEducation'
import TableOfPrices from 'components/Dashboard/TableOfPrices'
import MapWithPins from 'components/Dashboard/PlanningPermisMaps'

const generateComponents = (apiData) => {
    const components = []

    if (apiData.price_paid) {
        components.push(
            <Col className="mb-5 mb-xl-2" xl="6" key="averageYearlyPrices">
                <Card className="bg-gradient-default shadow">
                    <CardHeader className="bg-transparent">
                        <Row className="align-items-center">
                            <div className="col">
                                <h6 className="text-uppercase text-light ls-1 mb-1">
                                    Overview
                                </h6>
                                <h2 className="text-white mb-0">
                                    Average sold prices by year by bedrooms
                                </h2>
                            </div>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <AverageYearlyPrices data={apiData} />
                    </CardBody>
                </Card>
            </Col>,
        )
    }

    if (apiData.price_paid) {
        components.push(
            <Col className="mb-5 mb-xl-2" xl="6" key="latestSoldPrices">
                <Card className="shadow" style={{ height: '100%' }}>
                    <CardHeader className="bg-transparent">
                        <Row className="align-items-center">
                            <div className="col">
                                <h6 className="text-uppercase text-muted ls-1 mb-1">
                                    Last ten properties sold in the area
                                </h6>
                                <h2 className="mb-0">Sold properties</h2>
                            </div>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <LastestSoldPrices data={apiData} />
                    </CardBody>
                </Card>
            </Col>,
        )
    }

    if (apiData.tenureOfHouseHold) {
        components.push(
            <Col className="mb-5 mb-xl-2" xl="6" key="tenureOfHousehold">
                <Card className="bg-gradient-default shadow">
                    <CardHeader className="bg-transparent">
                        <Row className="align-items-center">
                            <div className="col">
                                <h6 className="text-uppercase text-light ls-1 mb-1">
                                    Tenure of household
                                </h6>
                                <h2 className="text-white mb-0">
                                    Types of tenure of households
                                </h2>
                            </div>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <TenureOfHousehold data={apiData} />
                    </CardBody>
                </Card>
            </Col>,
        )
    }

    if (apiData.populationByAge) {
        components.push(
            <Col className="mb-5 mb-xl-2" xl="6" key="populationByAge">
                <Card className="shadow">
                    <CardHeader className="bg-transparent">
                        <Row className="align-items-center">
                            <div className="col">
                                <h6 className="text-uppercase text-muted ls-1 mb-1">
                                    Population per year by age group
                                </h6>
                                <h2 className="mb-0">Population</h2>
                            </div>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <PopulationByAge data={apiData} />
                    </CardBody>
                </Card>
            </Col>,
        )
    }

    if (apiData.economicActivity) {
        components.push(
            <Col className="mb-5 mb-xl-2" xl="6" key="economicActivity">
                <Card className="bg-gradient-default shadow">
                    <CardHeader className="bg-transparent">
                        <Row className="align-items-center">
                            <div className="col">
                                <h6 className="text-uppercase text-light ls-1 mb-1">
                                    Economic Activity
                                </h6>
                                <h2 className="text-white mb-0">
                                    Activity for the area
                                </h2>
                            </div>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <EconomicActivity data={apiData} />
                    </CardBody>
                </Card>
            </Col>,
        )
    }

    if (apiData.levelOfEducation) {
        components.push(
            <Col className="mb-5 mb-xl-2" xl="6" key="levelOfEducation">
                <Card className="shadow">
                    <CardHeader className="bg-transparent">
                        <Row className="align-items-center">
                            <div className="col">
                                <h6 className="text-uppercase text-muted ls-1 mb-1">
                                    Level of Education
                                </h6>
                                <h2 className="mb-0">
                                    Summary of area level of education
                                </h2>
                            </div>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <LevelOfEducation data={apiData} />
                    </CardBody>
                </Card>
            </Col>,
        )
    }

    return components
}

const SearchResults = (props) => {
    const [apiData, setApiData] = useState(null)
    const [mapData, setMapData] = useState(null)
    const [geolocation, setGeolocation] = useState(null)
    const { accessToken, loading, logoutUser } = useContext(AuthContext)
    const [searchLoading, setSearchLoading] = useState(false)
    const endpoint = process.env.REACT_APP_PROPINTEL_API

    const fetchData = async () => {
        setSearchLoading(true)
        try {
            // Make the two API calls concurrently and handle their results individually
            const results = await Promise.allSettled([
                axios.get(`${endpoint}/postcode/${props.postcode}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }),
                axios.get(`${endpoint}/planning/${props.postcode}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }),
                axios.get(`${endpoint}/geolocation/${props.postcode}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }),
            ])

            // Process the results of the API calls
            const response = results[0]
            const mapResponse = results[1]
            const geolocationResults = results[2]

            if (response.status === 'fulfilled') {
                setApiData(response.value.data)
            } else {
                console.error('Failed to fetch main data:', response.reason)
                console.log(response)
                if (response.reason.response.status == 401) {
                    //Unauthorized
                    logoutUser()
                }
            }

            if (mapResponse.status === 'fulfilled') {
                setSearchLoading(false)
                setMapData(mapResponse.value.data)
            } else {
                console.error('Failed to fetch map data:', mapResponse.reason)
            }
            if (geolocationResults.status === 'fulfilled') {
                setGeolocation(geolocationResults.value.data)
            } else {
                console.error(
                    'Failed to retrieve geolocation data: ',
                    geolocation.reason,
                )
            }
        } catch (error) {
            console.log(error)
            logoutUser()
        }
        setSearchLoading(false)
    }

    useEffect(() => {
        if (props.postcode && !loading) {
            fetchData()
        }
    }, [props.postcode, loading])

    if (searchLoading) {
        return (
            <>
                <Header />
                <Container className="mt--7" fluid>
                    <Button color="primary" disabled>
                        <Spinner size="xl">Loading...</Spinner>
                        <span> Loading</span>
                    </Button>
                </Container>
            </>
        )
    }

    if (!apiData) {
        return (
            <>
                <Header />
            </>
        )
    }

    if (apiData.error) {
        return (
            <>
                <Header />
                <Container className="mt--7" fluid>
                    <Row className="justify-content-center text-center">
                        <Col md="6">
                            <Card className="bg-gradient-default shadow">
                                <h2 className="display-3 text-white">Error</h2>
                                <p className="lead text-white">
                                    {apiData.error} with {props.postcode}
                                </p>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }

    const components = generateComponents(apiData)

    return (
        <>
            <Header />
            <Container className="mt--7" fluid>
                {components.map((component, index) => (
                    <Row key={index}>
                        {components.slice(index * 2, index * 2 + 2)}
                    </Row>
                ))}
                <Row>
                    <Col className="mb-5 mb-xl-2" xl="12">
                        <TableOfPrices data={apiData} />
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-5 mb-xl-0" xl="12">
                        {geolocation && mapData ? (
                            <MapWithPins
                                data={mapData}
                                postcode={props.postcode}
                                geocodedData={geolocation}
                            />
                        ) : (
                            <div
                                className="d-flex justify-content-center align-items-center"
                                style={{ height: '400px' }}
                            >
                                <Spinner size="xl" color="primary" />
                                <span className="ml-3">Loading...</span>
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default SearchResults

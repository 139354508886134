import React, { useCallback, useState } from 'react'
import { Bar } from 'react-chartjs-2'
// javascipt plugin for creating charts
import Chart from 'chart.js'
// core components
import { chartOptions, parseOptions } from 'variables/charts.js'

const colors = [
    'rgba(255, 99, 132, 0.5)',
    'rgba(54, 162, 235, 0.5)',
    'rgba(255, 206, 86, 0.5)',
    'rgba(75, 192, 192, 0.5)',
    'rgba(153, 102, 255, 0.5)',
    'rgba(255, 159, 64, 0.5)',
    'rgba(199, 199, 199, 0.5)',
    'rgba(83, 102, 255, 0.5)',
    'rgba(255, 107, 107, 0.5)',
    'rgba(107, 255, 107, 0.5)',
]

const PopulationByAge = (props) => {
    // Group data by date
    // Group data by date
    const populationByAge = props.data.populationByAge
    const uniqueDates = Object.keys(
        populationByAge.date.reduce((acc, value) => {
            acc[value] = true
            return acc
        }, {}),
    )
    const groupedData = populationByAge.Age.reduce((acc, age, index) => {
        if (!acc[age]) {
            acc[age] = {}
        }

        const ageGroup = populationByAge.date[index]
        const value = populationByAge.value[index]
        if (!acc[age][ageGroup]) {
            acc[age][ageGroup] = 0
        }
        acc[age][ageGroup] += value
        return acc
    }, {})
    // Extract labels and datasets from groupedData
    const labels = uniqueDates
    const datasets = Object.entries(groupedData).map(
        ([date, values], index) => ({
            label: date,
            backgroundColor: colors[index % colors.length],
            data: Object.values(values),
            order: index,
        }),
    )
    const chartData = { labels, datasets }

    const options = {
        scales: {
            xAxes: [{ stacked: false }], // Disable stacking for X-axis
            yAxes: [{ stacked: false }], // Disable stacking for Y-axis
        },
        hover: {
            mode: null, // Disable hover effect
        },
    }
    // Read default options
    if (window.Chart) {
        parseOptions(Chart, chartOptions())
    }

    return (
        <div className="chart">
            <Bar data={chartData} options={options} />
        </div>
    )
}

export default PopulationByAge

import React from 'react'
import { Card, CardHeader, Col } from 'reactstrap'

const SignUpEmail = ({ email }) => {
    return (
        <>
            <Col lg="6" md="8">
                <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-transparent pb-5">
                        <div className="text-muted text-center mt-2 mb-4">
                            <small>
                                Verification email has been sent to {email}.
                                Please check your emails and follow the link to
                                validate.
                            </small>
                        </div>
                    </CardHeader>
                </Card>
            </Col>
        </>
    )
}

export default SignUpEmail

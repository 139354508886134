/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useParams, useNavigate } from 'react-router-dom'
// reactstrap components
import {
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    InputGroup,
    Button,
    Input,
    InputGroupText,
    InputGroupAddon,
} from 'reactstrap'

import Header from 'components/Headers/Header.js'
import SearchResults from './examples/SearchResults'
import { useState } from 'react'

const Index = (props) => {
    const { postcode } = useParams()
    const [searchBoxPC, setSearchBoxPC] = useState(null)
    const handleOnChange = (e) => {
        if (e.target.name == 'postcode-search') {
            setSearchBoxPC(e.target.value)
        }
    }
    const navigate = useNavigate()
    const handleOnSubmit = (e) => {
        e.preventDefault()
        if (searchBoxPC != null) {
            var url = searchBoxPC.replace(' ', '').replace('-', '')
            navigate(`/admin/postcode/${url}`)
        }
    }
    if (!postcode) {
        return (
            <>
                <Header />
                <Container>
                    <div className="header-body text-center mb-7">
                        <Row className="justify-content-center mb-5">
                            <Col lg="5" md="6">
                                <h1 className="text-primary">
                                    Welcome to the search page!
                                </h1>
                                <p className="text-lead text-primary">
                                    Please type in the postcode you would like
                                    to search for and use this to gather data
                                    for your investment.
                                </p>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col lg="5" md="6">
                                <Form
                                    role="form"
                                    onSubmit={(e) => handleOnSubmit(e)}
                                >
                                    <FormGroup>
                                        <InputGroup className="input-group-alternative mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-search" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="Post code"
                                                type="text"
                                                name="postcode-search"
                                                onChange={(e) =>
                                                    handleOnChange(e)
                                                }
                                            />
                                        </InputGroup>
                                        <Button
                                            className="btn-neutral btn-icon"
                                            color="default"
                                            href="#pablo"
                                            onClick={(e) => handleOnSubmit(e)}
                                        >
                                            <span className="btn-inner--text">
                                                Submit
                                            </span>
                                        </Button>
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </>
        )
    }
    return (
        <>
            <SearchResults postcode={postcode} />
        </>
    )
}

export default Index

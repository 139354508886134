import React, { useCallback, useState } from 'react'
import { Doughnut, Pie } from 'react-chartjs-2'
// javascipt plugin for creating charts
import Chart from 'chart.js'
// core components
import { chartOptions, parseOptions } from 'variables/charts.js'
import { parseJsonText } from 'typescript'

var backgroundColors = [
    'rgba(255, 99, 132, 0.5)',
    'rgba(54, 162, 235, 0.5)',
    'rgba(255, 206, 86, 0.5)',
    'rgba(75, 192, 192, 0.5)',
    'rgba(153, 102, 255, 0.5)',
    'rgba(255, 0, 0, 0.5)', // Red
    'rgba(0, 255, 0, 0.5)', // Green
    'rgba(255, 0, 255, 0.5)', // Magenta
    'rgba(0, 255, 255, 0.5)', // Cyan
]
const economicActivityMap = {
    'Economically active (excluding full-time students):In employment':
        'Total in employment',
    'Economically active (excluding full-time students):In employment:Employee':
        'Employee',
    'Economically active (excluding full-time students):In employment:Self-employed with employees':
        'Self-employed',
    'Economically active (excluding full-time students):In employment:Self-employed without employees':
        'Self-employed',
    'Economically active (excluding full-time students): Unemployed':
        'Unemployed',
    'Economically active and a full-time student:In employment': 'Student',
    'Economically active and a full-time student: Unemployed': 'Student',
    'Economically inactive: Retired': 'Retired',
    'Economically inactive: Student': 'Student',
    'Economically inactive: Looking after home or family':
        'Looking after family',
    'Economically inactive: Long-term sick or disabled': 'Sick or disabled',
    'Economically inactive: Other': 'Other',
}
const EconomicActivity = (props) => {
    if (!props.data.economicActivity) {
        console.error('Invalid data format')
        return []
    }
    var chartLabels = []
    var chartValues = []
    var dictionaryValues = {}
    var total = 0
    props.data.economicActivity.value.forEach((element, index) => {
        var shortEconomicActivity =
            economicActivityMap[
                props.data.economicActivity['Economic activity status'][index]
            ]
        if (shortEconomicActivity != 'Total in employment') {
            total += element
            if (!dictionaryValues[shortEconomicActivity]) {
                dictionaryValues[shortEconomicActivity] = element
            } else {
                dictionaryValues[shortEconomicActivity] += element
            }
        }
    })
    Object.entries(dictionaryValues).forEach(([key, value]) => {
        chartLabels.push(key)
        chartValues.push((value * 100) / total)
    })
    const pieOptions = {
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                usePointStyle: true,
                padding: 16,
            },
        },
        tooltips: {
            callbacks: {
                label: function (item, data) {
                    var idx = item.index
                    var label = data.labels[idx]
                    var value = Math.round(data.datasets[0].data[idx])
                    var content = label + ': ' + value + '%'
                    return content
                },
            },
        },
    }

    // Example data
    if (window.Chart) {
        parseOptions(Chart, chartOptions())
    }
    const chartData = {
        labels: chartLabels,
        datasets: [
            {
                label: 'Economic activity',
                data: chartValues,
                backgroundColor: backgroundColors,
            },
        ],
    }

    return (
        <>
            <div className="chart">
                <Pie
                    data={{ ...chartData, type: 'pie' }}
                    options={pieOptions}
                />
            </div>
        </>
    )
}

export default EconomicActivity

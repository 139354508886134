import React from 'react'
import { Bar } from 'react-chartjs-2'

const LastestSoldPrices = (props) => {
    const allowedPropertyTypes = ['F', 'L', 'S', 'D', 'T']
    let chartData = {
        options: {
            scales: {
                yAxes: [
                    {
                        ticks: {
                            callback: function (value) {
                                if (!(value % 10)) {
                                    //return '$' + value + 'k'
                                    return value
                                }
                            },
                        },
                    },
                ],
            },
            tooltips: {
                callbacks: {
                    label: function (item, data) {
                        var label = data.datasets[item.datasetIndex].label || ''
                        var yLabel = item.yLabel
                        var content = ''
                        if (data.datasets.length > 1) {
                            content += label
                        }
                        content += '£' + yLabel.toLocaleString()
                        return content
                    },
                    title: function (item, data) {
                        var postcode = data.datasets[0].postcodes[item[0].index]
                        return postcode
                    },
                },
            },
        },
        data: {
            labels: [],
            datasets: [
                {
                    label: 'Sales',
                    data: [],
                    maxBarThickness: 10,
                    postcodes: [],
                },
            ],
        },
    }
    let myData = props.data
    let counter = 0
    for (
        let index = myData.price_paid.length - 1;
        counter < 10 && index > 0;
        index--
    ) {
        if (allowedPropertyTypes.includes(myData.property_type[index])) {
            chartData.data.datasets[0].data.unshift(myData.price_paid[index])
            chartData.data.datasets[0].postcodes.unshift(myData.postcode[index])
            chartData.data.labels.unshift(myData.deed_date[index])
            counter++
        }
    }

    return (
        <>
            <div className="chart">
                <Bar data={chartData?.data} options={chartData?.options} />
            </div>
        </>
    )
}
export default LastestSoldPrices

import React, { useState } from 'react'
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
} from 'reactstrap'
import axios from 'axios'
import HomeNavbar from 'components/Navbars/HomeNavbar.js'
import AuthFooter from 'components/Footers/AuthFooter.js'

const ContactUs = () => {
    const endpoint = process.env.REACT_APP_PROPINTEL_API
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    })
    const [formStatus, setFormStatus] = useState(null)

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const response = await axios.post(
                endpoint + '/contact/send-email/',
                formData,
            )
            setFormStatus(response.data.message)
        } catch (error) {
            setFormStatus('Failed to send email. Please try again.')
        }
    }

    return (
        <>
            <HomeNavbar />
            <div className="main-content">
                <Container className="mt-5">
                    <Row className="justify-content-center">
                        <Col className="mb-5 mb-xl-2" xl="6">
                            <Card className="bg-gradient-default shadow">
                                <CardHeader className="bg-transparent">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h6 className="text-uppercase text-light ls-1 mb-1">
                                                Get in Touch
                                            </h6>
                                            <h2 className="text-white mb-0">
                                                Contact Us
                                            </h2>
                                            <h6 className="text-light ls-1 mb-1">
                                                Use this form or alternatively
                                                send us an email on
                                                support@propintel.co.uk
                                            </h6>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={handleSubmit}>
                                        <FormGroup>
                                            <Label for="name">Name</Label>
                                            <Input
                                                type="text"
                                                name="name"
                                                id="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="email">Email</Label>
                                            <Input
                                                type="email"
                                                name="email"
                                                id="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="message">Message</Label>
                                            <Input
                                                type="textarea"
                                                name="message"
                                                id="message"
                                                value={formData.message}
                                                onChange={handleChange}
                                                required
                                            />
                                        </FormGroup>
                                        <Button type="submit" color="primary">
                                            Send
                                        </Button>
                                    </Form>
                                    {formStatus && (
                                        <div className="mt-3 alert alert-info">
                                            {formStatus}
                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <AuthFooter />
        </>
    )
}

export default ContactUs

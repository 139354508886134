/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from 'views/Index.js'
import Register from 'views/examples/Register.js'
import Home from 'views/examples/HomePage'
import Login from 'views/examples/Login.js'
import EmailVerification from 'views/examples/emailVerification'
import ResetPassword from 'views/examples/ResetPassword'
import ResetPasswordConfirmation from 'views/examples/ResetPasswordConfirmation'
import ReturnOnInvestment from 'views/examples/ReturnOnInvestment'
import AboutUs from 'views/examples/AboutUs'
import ContactUs from 'views/examples/ContactUs'

var routes = [
    {
        path: '/index',
        name: 'Search',
        icon: 'ni ni-tv-2 text-primary',
        component: <Index />,
        layout: '/admin',
    },
    /* {
    path: "/icons",
    name: "Icons",
    icon: "ni ni-planet text-blue",
    component: <Icons />,
    layout: "/admin",
  },
  {
    path: "/maps",
    name: "Maps",
    icon: "ni ni-pin-3 text-orange",
    component: <Maps />,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: <Profile />,
    layout: "/admin",
  },
  {
    path: "/tables",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: <Tables />,
    layout: "/admin",
  },*/
    {
        path: '/login',
        name: 'Login',
        icon: 'ni ni-key-25 text-info',
        component: <Login />,
        layout: '/auth',
    },
    {
        path: '/register',
        name: 'Register',
        icon: 'ni ni-circle-08 text-pink',
        component: <Register />,
        layout: '/auth',
    },
    {
        path: '/dj-rest-auth/registration/account-confirm-email/:key',
        name: 'email-verification',
        icon: 'ni ni-circle-08 text-pink',
        component: <EmailVerification />,
        layout: '/auth',
    },
    {
        path: '/roi',
        name: 'Return on investment',
        icon: 'ni ni-money-coins text-primary',
        component: <ReturnOnInvestment />,
        layout: '/admin',
    },
    {
        path: '/postcode/:postcode',
        name: 'PostcodeSearch',
        icon: 'ni ni-tv-2 text-primary',
        component: <Index />,
        layout: '/admin',
    },
    {
        path: '/reset_password',
        name: 'ResetPassword',
        icon: 'ni ni-key-25 text-pink',
        component: <ResetPassword />,
        layout: '/auth',
    },
    {
        path: '/reset_password_confirm/:uid/:token',
        name: 'ResetPasswordConfirmation',
        icon: 'ni ni-key-25 text-pink',
        component: <ResetPasswordConfirmation />,
        layout: '/auth',
    },
    {
        path: '/about-us',
        name: 'AboutUs',
        icon: 'ni ni-atom text-pink',
        component: <AboutUs />,
    },
    {
        path: '/contact-us',
        name: 'Contact us',
        icon: 'ni ni-atom text-pink',
        component: <ContactUs />,
    },
    {
        path: '*',
        name: 'Home-page',
        icon: 'ni ni-atom text-pink',
        component: <Home />,
        layout: '/home',
    },
]
export default routes

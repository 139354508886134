import React from 'react'
import { Bar } from 'react-chartjs-2'

const levelOfEducationMap = {
    'No qualifications': 'No qualifications',
    'Level 1 and entry level qualifications': 'lvl 1',
    'Level 2 qualifications': 'lvl 2',
    Apprenticeship: 'Apprenticeship',
    'Level 3 qualifications': 'lvl 3',
    'Level 4 qualifications or above': 'lvl 4',
    'Other qualifications': 'Other qualifications',
}
const LevelOfEducation = (props) => {
    if (!props.data.levelOfEducation) return []
    let chartData = {
        options: {
            scales: {
                yAxes: [
                    {
                        ticks: {
                            callback: function (value) {
                                return value
                            },
                        },
                    },
                ],
            },
        },
        data: {
            labels: [],
            datasets: [
                {
                    label: 'Sales',
                    data: [],
                    maxBarThickness: 10,
                },
            ],
        },
    }
    let mappedEducationLvl = []
    let rawEducationLvl =
        props.data.levelOfEducation['Highest level of qualification']
    for (let index = 0; index < rawEducationLvl.length; index++) {
        mappedEducationLvl.push(levelOfEducationMap[rawEducationLvl[index]])
    }
    let values = props.data.levelOfEducation['value']
    chartData.data.datasets[0].data = values
    chartData.data.labels = mappedEducationLvl
    return (
        <>
            <div className="chart">
                <Bar data={chartData?.data} options={chartData?.options} />
            </div>
        </>
    )
}
export default LevelOfEducation

import { useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Col,
} from 'reactstrap'
import { resetPasswordConfirmation } from 'utils/UsefulAPICalls'

const ResetPasswordConfirmation = () => {
    const [status, setStatus] = useState(false)
    const [error, setError] = useState()
    const { uid, token } = useParams()
    const [formData, setFormData] = useState({
        new_password1: '',
        new_password2: '',
    })
    const { new_password1, new_password2 } = formData
    const handleOnChange = (e) =>
        setFormData({ ...formData, [e.target.name]: e.target.value })
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            await resetPasswordConfirmation(
                uid,
                token,
                new_password1,
                new_password2,
            )

            setStatus(true)
        } catch (error) {
            setError(
                error.response
                    ? JSON.stringify(error.response.data)
                    : 'An Error has occured.',
            )
        }
    }
    if (status) {
        return <Navigate to={'../login/'}></Navigate>
    }
    return (
        <>
            <Col lg="5" md="7">
                <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                        <div className="text-center text-muted mb-4">
                            <small>Set your new password here.</small>
                        </div>
                        <Form role="form" onSubmit={handleSubmit}>
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-lock-circle-open" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        name="new_password1"
                                        placeholder="Password"
                                        type="password"
                                        autoComplete="new-password"
                                        onChange={(e) => handleOnChange(e)}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-lock-circle-open" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Confirm Password"
                                        type="password"
                                        autoComplete="new-password"
                                        name="new_password2"
                                        onChange={(e) => handleOnChange(e)}
                                    />
                                </InputGroup>
                            </FormGroup>
                            {error ? (
                                <div className="custom-control custom-control-alternative custom-checkbox">
                                    <span className="text-muted">{error}</span>
                                </div>
                            ) : null}
                            <div className="text-center">
                                <Button
                                    className="my-4"
                                    color="primary"
                                    type="button"
                                    onClick={(e) => handleSubmit(e)}
                                >
                                    Submit
                                </Button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </>
    )
}

export default ResetPasswordConfirmation

import axios from 'axios'

const django_api = process.env.REACT_APP_PROPINTEL_API

export const signup = async (
    first_name,
    last_name,
    email,
    password1,
    password2,
) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    }
    const body = JSON.stringify({
        email,
        first_name,
        last_name,
        password1,
        password2,
    })
    const response = await axios
        .post(`${django_api}/dj-rest-auth/registration/`, body, config)
        .catch((e) => {
            console.log(body)
            console.log(e.response.data)
        })
    return response
}

export const resetPassword = async (email) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    }
    const body = JSON.stringify({ email })
    try {
        const response = await axios.post(
            `${django_api}/dj-rest-auth/password/reset/`,
            body,
            config,
        )
        return response
    } catch (error) {
        throw error
    }
}

export const resetPasswordConfirmation = async (
    uid,
    token,
    new_password1,
    new_password2,
) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    }
    const body = JSON.stringify({ uid, token, new_password1, new_password2 })
    try {
        const response = await axios.post(
            `${django_api}/dj-rest-auth/password/reset/confirm/`,
            body,
            config,
        )
        return response
    } catch (error) {
        throw error
    }
}

/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Link } from 'react-router-dom'
// reactstrap components
import {
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Form,
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    InputGroup,
    Navbar,
    Nav,
    NavItem,
    NavLink,
    Container,
    Media,
} from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

const AdminNavbar = (props) => {
    let { user, loading, logoutUser } = props
    const navigate = useNavigate()
    const [postcode, setPostcode] = useState('')
    const submitForm = (event) => {
        event.preventDefault()
        var url = postcode.replace(' ', '').replace('-', '')
        navigate(`/admin/postcode/${url}`)
    }
    const logout = (e) => {
        e.preventDefault()
        logoutUser()
    }
    if (user && !loading) {
        return (
            <>
                <Navbar
                    className="navbar-top navbar-dark"
                    expand="md"
                    id="navbar-main"
                >
                    <Container fluid>
                        <Form
                            className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto"
                            onSubmit={submitForm}
                        >
                            <FormGroup className="mb-0">
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-search" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Search postcode"
                                        type="text"
                                        value={postcode}
                                        onChange={(e) =>
                                            setPostcode(e.target.value)
                                        }
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Form>
                        <Nav
                            className="align-items-center d-none d-md-flex"
                            navbar
                        >
                            <UncontrolledDropdown nav>
                                <DropdownToggle className="pr-0" nav>
                                    <Media className="align-items-center">
                                        <span className="avatar avatar-sm rounded-circle">
                                            <img
                                                alt="..."
                                                src={require('../../assets/img/theme/LogoInBlue-2.png')}
                                            />
                                        </span>
                                        <Media className="ml-2 d-none d-lg-block">
                                            <span className="mb-0 text-sm font-weight-bold">
                                                {user.first_name}
                                            </span>
                                        </Media>
                                    </Media>
                                </DropdownToggle>
                                <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                >
                                    <DropdownItem
                                        className="noti-title"
                                        header
                                        tag="div"
                                    >
                                        <h6 className="text-overflow m-0">
                                            Welcome!
                                        </h6>
                                    </DropdownItem>
                                    {/*
                                    <DropdownItem
                                        to="/admin/user-profile"
                                        tag={Link}
                                    >
                                        <i className="ni ni-single-02" />
                                        <span>My profile</span>
                                    </DropdownItem>
                                    <DropdownItem
                                        to="/admin/user-profile"
                                        tag={Link}
                                    >
                                        <i className="ni ni-settings-gear-65" />
                                        <span>Settings</span>
                                    </DropdownItem>
                                    <DropdownItem
                                        to="/admin/user-profile"
                                        tag={Link}
                                    >
                                        <i className="ni ni-calendar-grid-58" />
                                        <span>Activity</span>
                                    </DropdownItem>
                                    <DropdownItem
                                        to="/admin/user-profile"
                                        tag={Link}
                                    >
                                        <i className="ni ni-support-16" />
                                        <span>Support</span>
                                    </DropdownItem>
                                    <DropdownItem divider /> */}
                                    <DropdownItem>
                                        <div onClick={logout}>
                                            <i className="ni ni-user-run" />
                                            <span>Logout</span>
                                        </div>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Container>
                </Navbar>
            </>
        )
    } else {
        return (
            <>
                <Navbar
                    className="navbar-top navbar-dark"
                    expand="md"
                    id="navbar-main"
                >
                    <Container fluid>
                        <Link
                            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
                            to="/"
                        >
                            {props.brandText}
                        </Link>
                        <Nav
                            className="align-items-center d-none d-md-flex"
                            navbar
                        >
                            <NavItem>
                                <NavLink
                                    className="nav-link-icon"
                                    to="/auth/login"
                                    tag={Link}
                                >
                                    <i className="ni ni-key-25" />
                                    <span className="nav-link-inner--text">
                                        Login
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className="nav-link-icon"
                                    to="/auth/login"
                                    tag={Link}
                                >
                                    <i className="ni ni-circle-08 text-pink" />
                                    <span className="nav-link-inner--text">
                                        Register
                                    </span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Container>
                </Navbar>
            </>
        )
    }
}

export default AdminNavbar

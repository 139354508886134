/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'

import 'assets/plugins/nucleo/css/nucleo.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'assets/scss/argon-dashboard-react.scss'

import AdminLayout from 'layouts/Admin.js'
import AuthLayout from 'layouts/Auth.js'
import HomeLayout from 'layouts/Home.js'
import Home from 'views/examples/HomePage.js'
import { AuthProvider } from 'context/AuthContext'
import { GoogleOAuthProvider } from '@react-oauth/google'
import AboutUs from 'views/examples/AboutUs'
import ContactUs from 'views/examples/ContactUs'

const root = ReactDOM.createRoot(document.getElementById('root'))

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
root.render(
    <BrowserRouter>
        <GoogleOAuthProvider clientId={googleClientId}>
            <AuthProvider>
                <Routes>
                    <Route path="/admin/*" element={<AdminLayout />} />
                    <Route path="/auth/*" element={<AuthLayout />} />
                    <Route path="/home/*" element={<Home />} />
                    <Route path="/about-us/*" element={<AboutUs />} />
                    <Route path="/contact-us/*" element={<ContactUs />} />
                    <Route path="*" element={<Navigate to="/home" replace />} />
                </Routes>
            </AuthProvider>
        </GoogleOAuthProvider>
    </BrowserRouter>,
)

import React from 'react'
import { Container, Row, Col, Card, CardBody, CardHeader } from 'reactstrap'

import HomeNavbar from 'components/Navbars/HomeNavbar.js'

const AboutUs = () => {
    return (
        <>
            <HomeNavbar />
            <div className="main-content">
                <Container className="mt-5">
                    <Row className="justify-content-center">
                        <Col lg="8">
                            <Card className="shadow">
                                <CardHeader className="bg-light text-white">
                                    <h2 className="mb-0">About Us</h2>
                                </CardHeader>
                                <CardBody>
                                    <p className="lead">
                                        Welcome to <strong>PropIntel</strong>!
                                    </p>
                                    <p>
                                        At PropIntel, we are dedicated to
                                        providing the latest sold prices and
                                        insightful data analysis on the UK
                                        property market. As a proud service of
                                        Insightify Analytics, we leverage our
                                        expertise in data, data analysis, and
                                        statistics to specialize in property
                                        market insights.
                                    </p>
                                    <p>
                                        Our team is composed of seasoned
                                        property investors with several years of
                                        hands-on experience in the property
                                        market. This unique blend of knowledge
                                        and practical experience allows us to
                                        fine-tune our platform to meet the
                                        specific needs of investors, whether
                                        they are seasoned professionals or just
                                        starting out.
                                    </p>
                                    <h4>Our Expertise</h4>
                                    <p>
                                        Our team’s diverse experience
                                        encompasses various investment
                                        strategies, including buy-to-let,
                                        flipping, and other property investment
                                        approaches. This extensive background
                                        ensures that we understand the
                                        challenges and opportunities faced by
                                        investors and can provide the most
                                        relevant and practical insights.
                                    </p>
                                    <h4>Our Data</h4>
                                    <p>
                                        We gather and aggregate data from
                                        multiple reputable sources, including
                                        Estate Agents, the Office for National
                                        Statistics (ONS), and the Land Registry.
                                        By combining and analyzing this
                                        information, we deliver comprehensive
                                        and understandable insights that both
                                        seasoned investors and prospective
                                        first-time buyers can utilize
                                        effectively.
                                    </p>
                                    <h4>Our Mission</h4>
                                    <p>
                                        Our mission is to empower investors and
                                        buyers with accurate, actionable data
                                        and analysis. Whether you are looking to
                                        expand your investment portfolio or make
                                        informed decisions about your first
                                        property purchase, PropIntel is here to
                                        provide the information and tools you
                                        need.
                                    </p>
                                    <h4>Why Choose Us?</h4>
                                    <ul>
                                        <li>
                                            <strong>Expert Insights:</strong>{' '}
                                            Our team’s firsthand experience in
                                            property investment ensures that we
                                            provide insights that are practical
                                            and relevant.
                                        </li>
                                        <li>
                                            <strong>Comprehensive Data:</strong>{' '}
                                            We source data from multiple trusted
                                            entities to give you a complete view
                                            of the property market.
                                        </li>
                                        <li>
                                            <strong>
                                                User-Friendly Analysis:
                                            </strong>{' '}
                                            Our platform is designed to present
                                            data in an easy-to-understand
                                            format, making it accessible for
                                            everyone.
                                        </li>
                                    </ul>
                                    <p>
                                        Thank you for choosing PropIntel. We
                                        look forward to helping you navigate the
                                        property market with confidence and
                                        success.
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default AboutUs

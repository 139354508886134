import { createContext, useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { googleLogout } from '@react-oauth/google'

axios.defaults.withCredentials = true
const AuthContext = createContext({
    user: {},
    setUser: () => {},
    accessToken: null,
    setAccessToken: () => {},
})

export default AuthContext

export const AuthProvider = ({ children }) => {
    let [user, setUser] = useState(() =>
        localStorage.getItem('user')
            ? JSON.parse(localStorage.getItem('user'))
            : null,
    )
    let [accessToken, setAccessToken] = useState(() =>
        localStorage.getItem('accessTokens')
            ? JSON.parse(localStorage.getItem('accessToken'))
            : null,
    )
    const [loading, setLoading] = useState(true)
    const [loggedOut, setLoggedOut] = useState(true)
    const [withGoogle, setWithGoogle] = useState(false)
    const navigate = useNavigate()
    const django_api = process.env.REACT_APP_PROPINTEL_API
    const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
    const axiosInstance = axios.create({
        baseURL: django_api,
        withCredentials: true, // this is a must - read about it below
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    })

    useEffect(() => {
        const checkTokens = () => {
            const storedUser = localStorage.getItem('user')
            const storedToken = localStorage.getItem('accessToken')
            if (storedUser && storedToken) {
                setUser(JSON.parse(storedUser))
                setAccessToken(JSON.parse(storedToken))
            }
            setLoading(false)
        }
        checkTokens()
    }, [])

    let loginWithGoogle = async (accesstoken) => {
        await axiosInstance
            .post('dj-rest-auth/google/', {
                access_token: accesstoken,
            })
            .then((response) => {
                // Saving in local storage so it persist on page refresh
                localStorage.setItem(
                    'accessToken',
                    JSON.stringify(response.data.access),
                )
                localStorage.setItem('user', JSON.stringify(response.data.user))
                setAccessToken(response.data.access)
                setUser(response.data.user)
                setLoggedOut(false)
                localStorage.setItem('loggedOut', JSON.stringify(false))
                setWithGoogle(true)
                localStorage.setItem('withGoogle', JSON.stringify(true))
                navigate('/admin')
                return true
            })
            .catch((error) => {
                console.log('Error Login in')
                console.log(error)
                return false
            })
    }
    let loginUser = async (email, password) => {
        await axiosInstance
            .post(
                'dj-rest-auth/login/',
                JSON.stringify({
                    email,
                    password,
                }),
            )
            .then((response) => {
                // Saving in local storage so it persist on page refresh
                localStorage.setItem(
                    'accessToken',
                    JSON.stringify(response.data.access),
                )
                localStorage.setItem('user', JSON.stringify(response.data.user))
                setAccessToken(response.data.access)
                setUser(response.data.user)
                setLoggedOut(false)
                localStorage.setItem('loggedOut', JSON.stringify(false))
                navigate('/admin')
                return true
            })
            .catch((error) => {
                console.log('Error Login in')
                console.log(error)
                return false
            })
    }
    let logoutUser = async () => {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('loggedOut')
        localStorage.removeItem('user')
        setAccessToken(null)
        setUser(null)
        setLoggedOut(true)
        axiosInstance.post('/dj-rest-auth/logout/').then(() => {
            navigate('/home/index')
        })
        if (JSON.parse(localStorage.getItem(withGoogle)) == true) {
            console.log('Logging out from google')
            googleLogout().then(() => {
                navigate('/home/index')
            })
        }
        localStorage.removeItem('withGoogle')
    }

    let refreshToken = async () => {
        await axiosInstance
            .post(`/dj-rest-auth/token/refresh/`)
            .then((response) => {
                setAccessToken(response.data.access)
                localStorage.setItem(
                    'accessToken',
                    JSON.stringify(response.data.access),
                )
            })
            .catch((e) => {
                console.error(e)
                logoutUser()
            })
        if (loading) {
            setLoading(false)
        }
    }

    useEffect(() => {
        const initializeAuth = async () => {
            if (loading && accessToken) {
                await refreshToken()
                setLoading(false)
            } else {
                setLoading(false)
            }
        }

        initializeAuth()

        let interval
        if (accessToken) {
            interval = setInterval(refreshToken, 1000 * 60 * 5)
        }

        return () => {
            clearInterval(interval)
        }
    }, [accessToken, loading])

    let contextData = {
        user: user,
        accessToken: accessToken,
        loading: loading,
        loginUser: loginUser,
        loginWithGoogle: loginWithGoogle,
        logoutUser: logoutUser,
        refreshToken: refreshToken,
        loggedOut: loggedOut,
    }

    return (
        <AuthContext.Provider value={contextData}>
            {loading ? null : children}
        </AuthContext.Provider>
    )
}

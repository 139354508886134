/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
// react library for routing
import { Link } from 'react-router-dom'
// reactstrap components
import { Button, Card, CardBody, Container, Row, Col } from 'reactstrap'

function HomeHeader() {
    return (
        <>
            <div className="header bg-info pt-5 pb-7">
                <Container>
                    <div className="header-body">
                        <Row className="align-items-center">
                            <Col lg="6">
                                <div className="pr-5">
                                    <h1 className="display-2 text-white font-weight-bold mb-0">
                                        Welcome to PropIntel
                                    </h1>
                                    <h2 className="display-4 text-white font-weight-light">
                                        Your Premier Property Insight Platform
                                    </h2>
                                    <p className="text-white mt-4 lead">
                                        Discover the latest property prices,
                                        market trends, and local area insights.
                                        PropIntel empowers investors and
                                        homebuyers with up-to-date data to make
                                        informed decisions. Calculate ROI and
                                        unlock your property potential with
                                        PropIntel.
                                    </p>
                                    <div className="mt-5">
                                        <Button
                                            className="btn-neutral my-2"
                                            color="default"
                                            to="/auth/register"
                                            tag={Link}
                                        >
                                            Get started
                                        </Button>
                                        <Button
                                            className="my-2"
                                            color="default"
                                            to="/auth/login"
                                            tag={Link}
                                        >
                                            Login
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="6">
                                <Row className="pt-5">
                                    <Col md="6">
                                        <Card className="mb-2">
                                            <CardBody>
                                                <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow mb-4">
                                                    <i className="ni ni-square-pin" />
                                                </div>
                                                <h5 className="h3">
                                                    Comprehensive Data
                                                </h5>
                                                <p>
                                                    Access detailed histories of
                                                    sold property prices and
                                                    insightful local area
                                                    information.
                                                </p>
                                            </CardBody>
                                        </Card>
                                        <Card>
                                            <CardBody>
                                                <div className="icon icon-shape bg-gradient-info text-white rounded-circle shadow mb-4">
                                                    <i className="ni ni-money-coins" />
                                                </div>
                                                <h5 className="h3">
                                                    Investment Analysis
                                                </h5>
                                                <p>
                                                    Calculate your Return on
                                                    Investment with precision
                                                    and confidence.
                                                </p>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col className="pt-lg-5 pt-4" md="6">
                                        <Card className="mb-4">
                                            <CardBody>
                                                <div className="icon icon-shape bg-gradient-success text-white rounded-circle shadow mb-4">
                                                    <i className="ni ni-active-40" />
                                                </div>
                                                <h5 className="h3">
                                                    Rental Yield Insights
                                                </h5>
                                                <p>
                                                    Explore rental yields by
                                                    postcode to maximize your
                                                    rental investments.
                                                </p>
                                            </CardBody>
                                        </Card>
                                        <Card className="mb-4">
                                            <CardBody>
                                                <div className="icon icon-shape bg-gradient-warning text-white rounded-circle shadow mb-4">
                                                    <i className="ni ni-active-40" />
                                                </div>
                                                <h5 className="h3">
                                                    Deal Finder
                                                </h5>
                                                <p>
                                                    Utilize our powerful engine
                                                    to discover the best
                                                    buy-to-let deals across
                                                    England. Coming soon..
                                                </p>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <div className="separator separator-bottom separator-skew zindex-100">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="fill-default"
                            points="2560 0 2560 100 0 100"
                        />
                    </svg>
                </div>
            </div>
        </>
    )
}

export default HomeHeader
